<template>
  <div>
    <div class="layout">
      <div class="box">
        <img src="../assets/image/inform.81c5910.png" alt="" class="box_img"> 
        <div class="nav_inform">
          <div @click="lawShow">相关法律法规</div> 
          <div>|</div> 
          <div @click="ScopeShow">网上举报须知</div>
        </div> 
        <div class="inform_img">
          <img src="../assets/image/inform1.deff947.png" alt="">
        </div> 
        <div class="inform_content">
          <div>
            <!-- 受理举报的范围 -->
            <div class="scope" v-if="isScope">
              <div class="scope_title">
                <img src="../assets/image/law.d769008.png" alt="">受理举报的范围
              </div> 
              <div class="scope_content">
                <div> 本网络举报中心专门受理中甘国际范围内广大党员、职工群众、劳务人员及相关
                  联的单位和个人对中甘国际各级领导干部、党员、职工违规违纪问题、党风廉政问
                  题和腐败问题的检举和控告。具体规定可点击本网络举报中心“相关法律法规和制度”即可查看
                </div>
              </div> 
              <div class="scope_footer">
                <div></div> 
                <img src="../assets/image/xiayb.c120e79.jpg" alt="" @click="scopeNext">
              </div>
            </div>
            <!-- 举报须知 -->
            <div class="notice" v-if="isScope2">
              <div class="notice_title">
                <img src="../assets/image/law.d769008.png" alt="">举报须知
              </div> 
              <div class="notice_content">
                <div> 
                  1、举报人要根据实际情况进行检举、揭发和控告，应对所反映问题的真实性负责，不得捏造事实，诬告陷害他人。对提供假线索诬告陷害他人的，一经查实，依照有关规定严肃处理，构成犯罪的移送司法机关追究法律责任。
                </div> 
                <div> 
                  2、举报内容要求事实清楚，证据充分。对被举报人的姓名和行为发生的时间、地点、主要证据以及涉及人员等情况必须详细明确。
                </div> 
                <div> 
                  3、举报可采取匿名举报，也可采取实名举报，中甘国际提倡和鼓励实名举报，举报人须详细填写举报信息及联系方式，以便对信访问题调查处理情况及时反馈。
                </div> 
                <div> 
                  4、中甘国际纪委负责网络信访举报内容的受理、转办、督办和查办工作。纪委办公室负责办理过程中的举报信息的保密工作，负责对打击报复检举、控告、申诉人的调查处理工作。
                </div>
              </div> 
              <div class="notice_footer">
                <a href="/report" class="active">
                  <img src="../assets/image/wojs.1e46f50.jpg" alt="">
                </a> 
                <a href="/" class="active">
                  <img src="../assets/image/bujs.c394f00.jpg" alt="">
                </a>
              </div>
            </div>
            <!-- 相关法律法规 -->
            <div class="law" v-if="islawShow">
              <div class="law_title">
                <img src="../assets/image/law.d769008.png" alt="">举报须知
              </div> 
              <div class="law_content">
                <ul>
                  <li class="law_item" v-for="(item,index) in listData" :key="index">
                    <a v-if="item.url && item.url !== ''" :href="item.url">
                      <div>{{item.title }}</div> 
                    </a>
                    <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                      <div>{{item.title }}</div> 
                    </a>
                    <span>{{ dayjs(item.publishDate).format('YYYY年MM月DD日') }}</span>
                  </li>
                </ul>
              </div>
              <div style="margin-bottom: 30px;" v-if="listData.length!=0">
                <el-pagination 
                  background 
                  layout="prev, pager, next" 
                  :current-page="pageNum" 
                  :page-size="pageSize" 
                  :total="total" 
                  :pager-count="11" 
                  @current-change="currentChange" 
                >
                </el-pagination>
              </div>
            </div>
          </div> 
        </div> 
        <div class="inform_footer">
          © 版权所有：中国甘肃国际经济技术合作有限公司
        </div>
      </div> 
      <div class="number"></div>
    </div>
  </div>
</template>
<script>
import {
  articles,
} from '@/api/common'
export default {
  data() {
    return {
      isScope:true,
      islawShow: false,
      isScope2: false,
      pageSize: 15,
      pageNum: 1,
      total: 0,
      listData: [],
    }
  },
  mounted() {
    this.articles()
  },
  methods: {
    scopeNext(){
      this.isScope = false
      this.islawShow = false
      this.isScope2 = true
    },
    lawShow(){
      this.islawShow = true
      this.isScope = false
      this.isScope2 = false
    },
    ScopeShow(){
      this.isScope = true
      this.isScope2 = false
      this.islawShow = false
    },
    articles() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '0191',
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const {
          code,
          data
        } = res
        if (code === 1 && data) {
          _this.listData = data.rows
          _this.total = data.totalRows
        }
      })
    },
    currentChange(e) {
      var _this = this
      _this.pageNum = e
      _this.articles()
    }
  }
}
</script>

<style>
.layout {
  width: 1200px;
  margin: 0 auto;
}
.box {
  min-height: 500px;
  width: 1003px;
  margin: 30px auto;
  box-shadow: 2px 2px 20px #a5a5a5;
}
.box_img {
  width: 100%;
}
.nav_inform {
  padding: 0 40px;
  display: flex;
  height: 44px;
  width: calc(100% - 80px);
  background: url(../assets/image//inform_back.d3b8a9a.jpg);
}
.nav_inform>div {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  line-height: 44px;
  padding: 0 20px;
  letter-spacing: 2px;
  cursor: pointer;
}
.inform_img {
  width: 1003px;
  height: 87px;
}
.inform_img>img {
  width: 100%;
  height: 100%;
}
.inform_content {
  width: 900px;
  padding: 10px 50px;
}
.scope {
  padding: 10px 10px 60px;
  border-radius: 10px;
  box-shadow: 0 0 10px #a5a5a5;
}
.scope_title {
  height: 46px;
  background-color: #dfeffc;
  padding: 4px 20px;
  border-bottom: 2px solid #abc2d7;
  font-size: 20px;
  color: #f60100;
  font-weight: 700;
  line-height: 46px;
  font-family: DFYaSongW9-GB;
  letter-spacing: 5px;
}
.scope_title>img {
  width: 53px;
  height: 47px;
  margin-right: 30px;
  margin-top: -4px;
}
.scope_content {
  padding: 50px 150px 50px 10px;
  font-size: 18px;
  line-height: 40px;
  text-indent: 2em;
}
.scope_footer {
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
}
.scope_footer>img {
  cursor: pointer;
}
.inform_footer {
  height: 100px;
  font-size: 18px;
  line-height: 100px;
  text-align: center;
}
.notice {
  padding: 10px 10px 60px;
  border-radius: 10px;
  box-shadow: 0 0 10px #a5a5a5;
}
.notice_title {
  height: 46px;
  background-color: #dfeffc;
  padding: 4px 20px;
  border-bottom: 2px solid #abc2d7;
  font-size: 20px;
  color: #f60100;
  font-weight: 700;
  line-height: 46px;
  font-family: DFYaSongW9-GB;
  letter-spacing: 5px;
}
.notice_title>img {
  width: 53px;
  height: 47px;
  margin-right: 30px;
  margin-top: -4px;
}
.notice_content {
  padding: 50px 10px;
  font-size: 18px;
  line-height: 30px;
  text-indent: 2em;
}
.notice_footer {
  padding: 0 230px;
  display: flex;
  justify-content: space-between;
}
.notice_footer img {
  cursor: pointer;
}
.law {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #a5a5a5;
}
.law_title {
  height: 46px;
  background-color: #dfeffc;
  padding: 4px 20px;
  border-bottom: 2px solid #abc2d7;
  font-size: 20px;
  color: #f60100;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 5px;
}
.law_title>img {
  width: 53px;
  height: 47px;
  margin-right: 30px;
  margin-top: -4px;
}
.law_content {
  padding: 50px 10px;
  font-size: 18px;
  line-height: 30px;
  min-height: 100px;
}
.law_item {
  display: flex;
  justify-content: space-between;
}
.law_item a div {
  font-size: 14px;
  font-family: "Microsoft YaHei";
}
.law_item a div {
  color: #000;
  font-weight: 600;
  line-height: 36px;
  cursor: pointer;
  float: left;
}
.law_item span {
  color: #333;
  float: right;
  font-size: 14px;
  font-family: "Microsoft YaHei";
}
</style>